import logo from "./logo.svg";
import "./App.css";
import NavBar from "./components/NavBar";
import HeroPage from "./components/HeroPage";
import SkillsPage from "./components/SkillsPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MyProjects from "./components/MyProjects";
import AccountingAppProject from "./components/AccountingAppProject";
import EventManagment from "./components/EventManagment";
import Movieproject from "./components/MovieProject";

const ScrollToHashElement = () => {
  const { hash, key } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash, key]);

  return null;
};

function App() {
  return (
    <div className="flex flex-col  h-screen w-full  ">
      <NavBar />
      <ScrollToHashElement />
      <div className=" w-full  snap-y snap-mandatory overflow-y-scroll">
        <Routes>
          {/* <Route
            path="/"
            element={
              <>
                <HeroPage />
                <MyProjects />
                <SkillsPage />
              </>
            }
          /> */}

          <Route
            path="/"
            element={
              <>
                <HeroPage />
                <MyProjects />
                <SkillsPage />
              </>
            }
          />

          <Route path="projects/1" element={<AccountingAppProject />} />
          <Route path="projects/2" element={<EventManagment />} />
          <Route path="projects/3" element={<Movieproject />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
