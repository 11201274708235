import { useNavigate } from "react-router-dom";
import bookkeepingImage from "../bookkeeping.png";
import eventApp from "../eventapp.png";
import movieApp from "../movieApp.png";
import AccountingAppProject from "./AccountingAppProject";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const projectList = [
  {
    id: 1,
    name: "Accounting Web Application",
    image: bookkeepingImage,
    bio: "An intuitive, full-stack web application catering to accounting and bookkeeping needs.",
    tags: ["Angular", "Node.js", "Tailwind CSS", "Express"],
  },
  {
    id: 2,
    name: "Event Management Web Application",
    image: eventApp,
    bio: "A freelance project to improve organization and team coordination for events. Built using Angular, Node.js and Express.",
    tags: ["Angular", "Node.js", "Tailwind CSS", "Express"],
  },

  {
    id: 3,
    name: "Event Management Web Application",
    image: movieApp,
    bio: "A personal project to expand my skills in web development and API integration. Developed using React, incorporating open-source APIs to fetch and display movie data.",
    tags: ["React", "Open-source API", "CSS"],
  },
];

export function MyProjects() {
  return (
    <div
      id="projects"
      className="flex flex-col gap-2 md:gap-3 p-4 md:p-10 snap-start  md:h-[calc(100vh-65px)] h-[calc(100vh-106px)]  w-full"
    >
      <Title />
      <Projects />
    </div>
  );
}

function Title() {
  return (
    <div className="flex flex-col p-2 rounded-md   md:w-full">
      <div className="bg-gradient-to-t  font-extrabold from-blue-300 to-indigo-400 bg-clip-text text-transparent">
        <label className="md:text-3xl text-2xl">Projects </label>
      </div>

      <p className="md:text-base text-sm text-gray-500 ">
        Here you can view my projects
      </p>
    </div>
  );
}

function Projects() {
  return (
    <div className="flex w-full overflow-y-auto md:h-[calc(100vh-16px)] h-[calc(100%-70px)] md:p-2 p-6 rounded-md bg-gray-100">
      <ProjectList />
    </div>
  );
}

function ProjectList() {
  return (
    <div className="flex flex-wrap justify-center items-center w-full gap-2 md:gap-10">
      {projectList.map((item) => (
        <Cards item={item} key={item.id} />
      ))}
    </div>
  );
}

function Cards({ children, item }) {
  return (
    <div>
      <Link
        className="bg-white justify-center md:justify-between shadow-lg gap-2 md:gap-4 md:p-4 p-3 flex md:flex-col rounded-md md:w-[400px] md:h-[474px] w-80 h-50 cursor-pointer"
        to={`/projects/${item.id}`}
      >
        <div className="flex flex-col">
          <img
            className="md:h-[240px]  md:w-full h-[150px] w-full object-cover rounded-md md:object-cover "
            src={item.image}
            alt={item.name}
          />
          <div className="md:flex md:gap-3 md:flex-col">
            <label className="md:text-base text-[#6090E7] font-bold text-sm underline pt-4 pl-2">
              {item.name}
            </label>
            {window.innerWidth >= 768 && (
              <p className="text-gray-500 text-sm">{item.bio}</p>
            )}
          </div>
        </div>

        <div className="md:flex hidden md:flex-wrap gap-2">
          {item.tags.map((tag) => (
            <Tags key={tag}>{tag}</Tags>
          ))}
        </div>
      </Link>
    </div>
  );
}

function Tags({ children }) {
  return (
    <div
      className="rounded-full  font-regular font-rubik text-xs md:text-sm px-3 py-1"
      style={{ backgroundColor: "#E6F1FF", color: "#305EB3" }}
    >
      {children}
    </div>
  );
}

export default MyProjects;
