import React from "react";
import { useParams } from "react-router-dom";
import image from "../assets/input-event-page.png";
import loginPage from "../loginpage.png";
import documentPage from "../assets/event-home-page.png";
import documentInput from "../assets/input-event-page.png";
import clientPage from "../assets/event-assignment-page.png";
import clientInput from "../assets/statistics-page.png";

function EventManagment() {
  return (
    <div className="flex flex-col md:h-[calc(100vh-50px)]  h-screen w-full">
      <Page1 />
    </div>
  );
}

function Page1() {
  return (
    <div className="md:flex pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <Title />
      <Content />
    </div>
  );
}

function Page2() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Document page</label>

        <div className="flex bg-gray-200 shadow p-1 rounded-md">
          <img className="rounded-md  w-[1300px]" src={documentPage} alt="" />
        </div>
      </div>
    </div>
  );
}

function Page3() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Document Input</label>

        <div className="flex bg-gray-500 shadow p-1 rounded-md">
          <img className="rounded-md  w-[1300px]" src={documentInput} alt="" />
        </div>
      </div>
    </div>
  );
}

function Page4() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Client page</label>

        <div className="flex bg-gray-200 shadow p-1 rounded-md">
          <img className="rounded-md  w-[1300px]" src={clientPage} alt="" />
        </div>
      </div>
    </div>
  );
}

function Page5() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Client page</label>

        <div className="flex bg-gray-400 shadow p-1 rounded-md">
          <img className="rounded-md  w-[1300px]" src={clientInput} alt="" />
        </div>
      </div>
    </div>
  );
}

function Title() {
  return (
    <div className="bg-white flex flex-col gap-4 w-full p-2">
      <label className="text-4xl ">Event Management Web Application</label>
      <ProjectDetails />
    </div>
  );
}

function ProjectDetails() {
  return (
    <div className="text-gray-400 gap-10 flex flex-col">
      <p>
        A freelance project to improve organization and team coordination for
        events. Built using Angular, Node.js and Express. <br /> While
        developing this project I learned more about user research, improving
        client communication, creating a project that will give the client more
        support to improve their business.
      </p>
    </div>
  );
}

function Content() {
  return (
    <div className="flex shadow-lg p-2 w-full rounded-md  justify-center">
      <img className="rounded-md " src={image} alt="" />
    </div>
  );
}
export default EventManagment;
