import React from "react";
import { useParams } from "react-router-dom";
import image from "../documentpage.png";
import loginPage from "../loginpage.png";
import documentPage from "../documentpage.png";
import documentInput from "../documentinput.png";
import clientPage from "../clientpage.png";
import clientInput from "../clientinput.png";

function AccountingAppProject() {
  return (
    <div className="flex flex-col md:h-[calc(100vh-50px)]  h-screen w-full">
      <Page1 />
    </div>
  );
}

function Page1() {
  return (
    <div className="md:flex md:pt-10   snap-start  md:gap-5 items-center justify-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <Title />
      <Content />
    </div>
  );
}

function Page2() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <div className="flex gap-2 flex-col">
          <label>Document page</label>

          <div className="flex bg-gray-200 shadow p-1 rounded-md">
            <img
              className="rounded-md  md:w-[1200px]"
              src={documentPage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Page3() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Document Input</label>

        <div className="flex bg-gray-400 shadow p-1 rounded-md">
          <img
            className="rounded-md  md:w-[1200px]"
            src={documentInput}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

function Page4() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Client page</label>

        <div className="flex bg-gray-200 shadow p-1 rounded-md">
          <img className="rounded-md  md:w-[1200px]" src={clientPage} alt="" />
        </div>
      </div>
    </div>
  );
}

function Page5() {
  return (
    <div className="md:flex flex-col pt-10  snap-start  md:gap-5 justify-center items-center md:p-10 md:h-[calc(100vh-50px)] h-[calc(100vh-106px)]  w-full ">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <label>Client page</label>

        <div className="flex bg-gray-400 shadow p-1 rounded-md">
          <img className="rounded-md md:w-[1200px]" src={clientInput} alt="" />
        </div>
      </div>
    </div>
  );
}

function Title() {
  return (
    <div className="bg-white h-full justify-center flex flex-col gap-4 w-full p-3 md:p-2">
      <label className="text-4xl ">Accounting Web Application</label>
      <ProjectDetails />
    </div>
  );
}

function ProjectDetails() {
  return (
    <div className="text-gray-500 gap-10 flex flex-col">
      <p className="md:text-base text-sm">
        An intuitive, full-stack web application catering to accounting and
        bookkeeping needs. <br /> Developed as a freelance project using
        Angular, Node.js and Express. <br /> <br /> This project enhanced my
        understanding of document organization <br /> and financial record
        retrieval, significantly improving <br /> overall operational efficiency
      </p>
    </div>
  );
}

function Content() {
  return (
    <div className="md:flex md:shadow-lg md:p-2 md:w-full md:border md:rounded-md hidden md:justify-center">
      <img className="rounded-md " src={image} alt="" />
    </div>
  );
}
export default AccountingAppProject;
