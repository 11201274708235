import Lottie, { LottieRefCurrentProps } from "lottie-react";
import AnimationData from "../Animation - 1718815858408.json";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import HeroPage from "./HeroPage";
import { HashLink } from "react-router-hash-link";
import ScrollToHashElement from "../App";
import useSmoothScrollToHash from "../hooks/useScroll";

function NavBar() {
  return (
    <div className=" md:pl-20 md:pr-20 md:flex md:justify-between items-center md:gap-20 border-b border-gray-300 bg-white w-50 md:w-full">
      <Logo />
      <Menu />
    </div>
  );
}

function Logo() {
  const phoneRef = useRef < LottieRefCurrentProps > null;

  return (
    <Link to={"/"}>
      <div className="flex cursor-pointer items-center border-gray-300  border-b md:border-none ">
        <Lottie
          lottieRef={phoneRef}
          className="md:h-16 md:w-20 h-12  "
          animationData={AnimationData}
        />
        <div className="md:text-xl font-rubik font-bold text-blue-800">
          My Portfolio
        </div>
      </div>
    </Link>
  );
}

function Menu() {
  const location = useLocation();
  useSmoothScrollToHash();

  return (
    <>
      <div className="flex  p-3   gap-4 text-sm md:gap-20 ">
        <a className="px-2 hover:bg-blue-100 hover:text-blue-700 cursor-pointer  py-1 rounded-md text-blue-500 border border-blue-400  md:border-none md:text-black md:font-normal">
          {location.pathname === "/" ? (
            <Link smooth to={"/#home"}>
              Home
            </Link>
          ) : (
            <Link to="/">Home</Link>
          )}
        </a>

        <a className="px-2  hover:bg-blue-100 hover:text-blue-700 py-1 rounded-md text-blue-500  border border-blue-400  md:border-none md:text-black md:font-normal">
          {location.pathname === "/" ? (
            <Link smooth to={"/#projects"}>
              Projects
            </Link>
          ) : (
            <Link to="/#projects">Projects</Link>
          )}
        </a>

        <a className="px-2 hover:bg-blue-100  hover:text-blue-700 py-1 rounded-md text-blue-500 border border-blue-400  md:border-none md:text-black md:font-normal">
          {location.pathname === "/" ? (
            <Link smooth to={"/#skills"}>
              Skills
            </Link>
          ) : (
            <Link to="/#skills">Skills</Link>
          )}
        </a>
      </div>
      <div className="flex items-center gap-20">
        <Links />
      </div>
    </>
  );
}

function Links() {
  return (
    <div className="md:flex hidden md:gap-4">
      <a href="https://www.linkedin.com/in/sofija-vujosevic/">
        <svg
          fill="#000000"
          version="1.1"
          id="Layer_1"
          viewBox="-143 145 512 512"
          width="34px"
          height="34px"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path d="M329,145h-432c-22.1,0-40,17.9-40,40v432c0,22.1,17.9,40,40,40h432c22.1,0,40-17.9,40-40V185C369,162.9,351.1,145,329,145z M339,617c0,5.5-4.5,10-10,10h-432c-5.5,0-10-4.5-10-10V185c0-5.5,4.5-10,10-10h432c5.5,0,10,4.5,10,10V617z"></path>{" "}
              <rect x="-8.5" y="348.4" width="49.9" height="159.7"></rect>{" "}
              <path d="M177.7,346.9c-28.6,0-46.5,15.6-49.8,26.6v-25.1H71.8c0.7,13.3,0,159.7,0,159.7h56.1v-86.3c0-4.9-0.2-9.7,1.2-13.1 c3.8-9.6,12.1-19.6,27-19.6c19.5,0,28.3,14.8,28.3,36.4v82.6H241v-88.8C241,369.9,213.2,346.9,177.7,346.9z"></path>{" "}
              <path d="M15.4,273c-18.4,0-30.5,11.9-30.5,27.7c0,15.5,11.7,27.7,29.8,27.7h0.4c18.8,0,30.5-12.3,30.4-27.7 C45.1,284.9,33.8,273,15.4,273z"></path>{" "}
            </g>{" "}
          </g>
        </svg>
      </a>
      <a href="https://github.com/SofijaVvv">
        <svg
          fill="#000000"
          height="34px"
          width="34px"
          version="1.1"
          id="Layer_1"
          viewBox="-143 145 512 512"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path d="M329,145h-432c-22.1,0-40,17.9-40,40v432c0,22.1,17.9,40,40,40h432c22.1,0,40-17.9,40-40V185C369,162.9,351.1,145,329,145z M339,617c0,5.5-4.5,10-10,10h-432c-5.5,0-10-4.5-10-10V185c0-5.5,4.5-10,10-10h432c5.5,0,10,4.5,10,10V617z"></path>{" "}
              <path d="M224.5,351.2c0.9-20.1-1.8-39.6-8.6-58.6c-0.9-2.4-2-3.3-4.5-2.6c-5.6,1.7-11.4,3-16.9,5c-15.2,5.5-29.1,13.5-42.6,22.2 c-1.4,0.9-3.5,1.5-5.2,1.2c-22.1-3.9-44.3-3.7-66.5-0.3c-2.2,0.3-5-0.2-6.8-1.3c-15.7-10.2-31.7-20.2-50-25 c-15.1-4-12-4.8-16.3,8.9c-5.1,16.4-7.1,33.4-6,50.6c0.1,1.2-0.8,2.7-1.6,3.8c-6.6,7.9-11.7,16.6-14.8,26.4c-6,19-4.8,38.2-1,57.3 c7.5,37.5,32.8,63.8,70.2,70.3c19.3,3.4,39.2,3.7,57.3,5.2c20.2-1.5,38.9-1.6,57.1-4.5c31.8-5.1,55.8-22,67.8-52.7 c4.2-10.7,6.5-22.4,7.9-33.8c3.1-25.2-1.2-48.8-18.4-68.7C225,353.8,224.5,352.4,224.5,351.2z M208.1,459.1 c-3.3,15.6-12.4,26.3-27.6,31.8c-14.7,5.4-29.9,7.6-45.4,8.6c-7.5,0.5-15,0.1-22.5,0.1c-20.2,0.4-40.4-0.4-59.9-6.2 c-24.5-7.3-35.5-21.9-36.2-47.5c-0.3-9.1,0.7-17.9,5-26.1c8.5-16.5,23.2-22.3,40.6-22.6c9.5-0.1,19.1,0.7,28.7,1.6 c20.1,1.8,40-0.1,60-1.2c8.8-0.5,17.8-0.7,26.4,0.8c18.5,3.2,32.5,21.6,32.8,42.3C210,446.8,209.3,453.1,208.1,459.1z"></path>{" "}
              <path d="M56.1,424.8c-5.5,5.7-7.4,12.9-7.7,19.3c0,9.6,2.8,17.3,7.7,22.3c6.8,6.9,16.1,6.7,22.6-0.3c9.8-10.6,9.7-30.5,0-41 C72.2,418,62.7,417.9,56.1,424.8z"></path>{" "}
              <path d="M148.2,424c-11.2,10.3-11.2,33,0,43.2c6.5,5.9,15.3,5.6,21.4-0.7c5.6-5.8,7.6-13.1,7.8-21c-0.2-7.9-2.2-15.1-7.9-20.9 C163.4,418.3,154.6,418.1,148.2,424z"></path>{" "}
            </g>{" "}
          </g>
        </svg>
      </a>
      <a href="https://www.behance.net/somfiiiiii">
        <svg
          fill="#000000"
          version="1.1"
          id="Layer_1"
          viewBox="0 0 512 512"
          width="34px"
          height="34px"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path d="M472,0H40C17.9,0,0,17.9,0,40v432c0,22.1,17.9,40,40,40h432c22.1,0,40-17.9,40-40V40C512,17.9,494.1,0,472,0z M482,472 c0,5.5-4.5,10-10,10H40c-5.5,0-10-4.5-10-10V40c0-5.5,4.5-10,10-10h432c5.5,0,10,4.5,10,10V472z"></path>{" "}
              <path d="M373.6,235.8c-5.3-7.9-12-13.7-20.2-17.3c-8.1-3.7-17.3-5.5-27.6-5.4c-17.1,0-31,5.4-41.8,16.2 c-10.7,10.8-16.1,26.3-16.1,46.6c0,21.6,6,37.2,17.9,46.8c11.9,9.6,25.6,14.4,41.2,14.4c18.9,0,33.5-5.7,44-17.1 c6.7-7.2,10.5-14.3,11.3-21.2h-31.2c-1.8,3.5-3.9,6.2-6.3,8.1c-4.4,3.6-10.1,5.4-17,5.4c-6.6,0-12.2-1.5-16.9-4.4 c-7.7-4.7-11.7-13-12.2-24.7h85.2c0.2-10.1-0.2-17.8-1-23.2C381.5,250.8,378.4,242.7,373.6,235.8z M299.5,263 c1.2-7.6,3.9-13.6,8.2-18.1c4.3-4.4,10.4-6.7,18.2-6.7c7.2,0,13.3,2.1,18.2,6.3c4.8,4.2,7.5,10.4,8.1,18.5H299.5z"></path>{" "}
              <rect x="291.6" y="182.3" width="67.8" height="19.7"></rect>{" "}
              <path d="M233.3,248.3c5.3-2.7,9.4-5.7,12.1-9c4.9-5.9,7.3-13.7,7.3-23.3c0-9.4-2.4-17.5-7.2-24.2c-8.1-11-21.8-16.6-41-16.9H128 v158.3h71.2c8.1,0,15.5-0.7,22.4-2.1c6.9-1.5,12.9-4.1,17.9-8c4.5-3.3,8.2-7.5,11.2-12.4c4.8-7.4,7.1-15.8,7.1-25.2 c0-9.1-2.1-16.8-6.2-23.2C247.5,255.9,241.4,251.3,233.3,248.3z M159.5,202.4h34.4c7.6,0,13.8,0.9,18.7,2.5 c5.7,2.4,8.5,7.2,8.5,14.6c0,6.6-2.1,11.3-6.4,13.9c-4.3,2.6-9.8,3.9-16.7,3.9h-38.5V202.4z M214,302.8c-3.8,1.9-9.1,2.8-16,2.8 h-38.5v-42.2h39c6.8,0.1,12,1,15.8,2.7c6.8,3.1,10.1,8.8,10.1,17C224.4,292.8,221,299.3,214,302.8z"></path>{" "}
            </g>{" "}
          </g>
        </svg>
      </a>
    </div>
  );
}

export default NavBar;
